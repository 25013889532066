import React, { useState } from 'react';
import { Menu, Switch } from 'antd';

const { SubMenu } = Menu;

const MenuComponent = () => {
  const [theme, setTheme] = useState('dark')
  const [current, setCurrent] = useState('1')

  const changeTheme = (value) => {
    setTheme(value ? 'dark' : 'light')
    // this.setState({
    //   theme: value ? 'dark' : 'light',
    // });
  };

  const handleClick = e => {
    console.log('click ', e);
    setCurrent(e.key)
    // this.setState({
    //   current: e.key,
    // });
  };
  return (
    <>
        {/* <Switch
          checked={state.theme === 'dark'}
          onChange={changeTheme}
          checkedChildren="Dark"
          unCheckedChildren="Light"
        className="switch-menu"
        /> */}
        <Menu
        id="menu-content"
          theme={theme}
          onClick={handleClick}
          style={{ width: 180 }}
          // defaultOpenKeys={['sub1']}
          mode="inline"
          selectedKeys={current}
          mode="inline"
        >
          <SubMenu key="sub1" title="Categorías">
            <Menu.Item key="1">MTB</Menu.Item>
            <Menu.Item key="2"><a href="/skateboarding">SKATEBOARDING</a></Menu.Item>
            <Menu.Item key="3">EPPS</Menu.Item>
            <Menu.Item key="4">INMOBILIARIA</Menu.Item>
          </SubMenu>
          
        </Menu>
      </>
  )
}

export default MenuComponent;
