const config = {
  apiKey: "AIzaSyCJG9Fbo3V_quHsUrSz149R32TugfA6e54",
  authDomain: "tusaccesoriosperu.firebaseapp.com",
  projectId: "tusaccesoriosperu",
  storageBucket: "tusaccesoriosperu.appspot.com",
  messagingSenderId: "520375541138",
  appId: "1:520375541138:web:85796a38f3e3be14fa354b",
  measurementId: "G-G1276YGFJ4"
}
export default config;
