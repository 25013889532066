export const AccesoriesGoPRo = [
  {
    cod: 100001,
    name: 'Arnés de pecho',
    price: 45,
    img: './images/gopro/arnes-pecho.png',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100002,
    name: 'Tuercas',
    price: 5,
    img: './images/gopro/tuercas.png',
    desc: 'Tuerca metálica con sujetador'
  },
  {
    cod: 100003,
    name: 'Sujetador con chupón',
    price: 30,
    img: './images/gopro/chupon.png',
    desc: 'Soporte para superficies como el vidrio y sus variantes. Incluye sujetador de gancho'
  },
  {
    cod: 100004,
    name: 'Gancho 360°',
    price: 30,
    img: './images/gopro/360.png',
    desc: 'Gancho de alta presión con soporte rotatorio 360°'
  },
  {
    cod: 100005,
    name: 'Mano sujetador tubular',
    price: 35,
    img: './images/gopro/mano-tubular.png',
    desc: 'Sujetador a estructura tubular con adaptador de tuerca'
  },
  {
    cod: 100006,
    name: 'Pegatinas',
    price: 6,
    img: './images/gopro/pegatinas.png',
    desc: 'Adhesivos por ambos lados para base curvo o plana'
  },
  {
    cod: 100007,
    name: 'Base plana para casco',
    price: 12,
    img: './images/gopro/base-plana.png',
    desc: 'Base perfecta para superficies planas y de alta resistencia'
  },
  {
    cod: 100008,
    name: 'Base curvo para casco',
    price: 16,
    img: './images/gopro/base-curvo.png',
    desc: 'Base perfecta para superficies curvas y de alta resistencia'
  },
  {
    cod: 100009,
    name: 'Brazo extensión corto',
    price: 12,
    img: './images/gopro/brazo-corto.png',
    desc: 'Extensión de 3.5cm aprox para articulación y manipulación de camara'
  },
  {
    cod: 100010,
    name: 'Brazo extensión largo',
    price: 16,
    img: './images/gopro/brazo-largo.png',
    desc: 'Extensión de 5cm aprox para articulación y manipulación de camara'
  },
  {
    cod: 100011,
    name: 'Gancho standar',
    price: 14,
    img: './images/gopro/gancho.png',
    desc: 'Gancho para base curvo o plana de alta resistencia'
  },
  {
    cod: 100012,
    name: 'Gancho J',
    price: 17.50,
    img: './images/gopro/gancho-J.png',
    desc: 'Gancho con extensión de brazo corto incluida'
  },
  {
    cod: 100013,
    name: 'Base redondo a tuerca',
    price: 15.50,
    img: './images/gopro/base-redondo.png',
    desc: 'Adaptador giratorio con entrada de tuerca directa.'
  },
  {
    cod: 100014,
    name: 'Sujetador de bucéo',
    price: 30,
    img: './images/gopro/buceo.png',
    desc: 'Sujetador de mano ideal para deportes acuáticos'
  },
  {
    cod: 100015,
    name: 'Palo selfie',
    price: 30,
    img: './images/gopro/selfie.png',
    desc: 'Bastón selfie de 90cm extendido, para fantásticas capturas panorámicas'
  },
  {
    cod: 100016,
    name: 'Arnés de cabeza',
    price: 30,
    img: './images/gopro/vincha.png',
    desc: 'Vincha resistente y flexible con regulador deacuerdo al diámetro de la cabeza'
  },
  {
    cod: 100017,
    name: 'Sujetador de muñeca',
    price: 24,
    img: './images/gopro/muñeca.png',
    desc: 'Soporte con engranaje para cámara y cinta para sujetar brazo o muñeca de alta resistencia'
  },
  {
    cod: 100018,
    name: 'Sujetador de timón',
    price: 28,
    img: './images/gopro/sujetador-timon.png',
    desc: 'Sujetador a tubo de medida universal y engranaje a cámara, ideal para bicicletas y motos'
  },
  {
    cod: 100019,
    name: 'Maletín porta accesorios',
    price: 50,
    img: './images/gopro/maletin.png',
    desc: 'Maleta ideal para llevar accesorios y/o cámaras'
  },
  {
    cod: 100020,
    name: 'Case normal Gopro Black 7',
    price: 100,
    img: './images/gopro/case_black_7.jpg',
    desc: 'Frame protector básico para gopro'
  },
  {
    cod: 100021,
    name: 'Case Acuático Gopro Black 7',
    price: 120,
    img: './images/gopro/case_acuatico_black_7.jpg',
    desc: 'Frame proctector actuático con botones y máxima resistencia a la presión bajo el agua. (MAX 10mts)'
  },
  {
    cod: 100022,
    name: 'Case normal Gopro Session',
    price: 100,
    img: './images/gopro/case_session_4.png',
    desc: 'Frame protector básico para gopro session'
  },
  {
    cod: 100023,
    name: 'Adaptador para tabla de surf',
    price: 85,
    img: './images/gopro/soporte_tabla.jpeg',
    desc: 'Frame protector básico para gopro session'
  },
  {
    cod: 100024,
    name: 'Soporte para la quijada del casco',
    price: 65,
    img: './images/gopro/quijada-casco.png',
    desc: 'Soporte altamente resistente, te garantizará una estabilidad y vistas espectaculares, debido a su posición estratégica'
  },
  {
    cod: 100025,
    name: 'Soporte articulado de 4 piezas para casco',
    price: 70,
    img: './images/gopro/quijada-articulado.png',
    desc: 'Accesorio compuesto por 4 brazos para una mayor manipulación de la cámara'
  },
  {
    cod: 100026,
    name: 'Case Acuático para Gopro Black 8',
    price: 120,
    img: './images/gopro/case-acuatico-gopro8.png',
    desc: 'Protector acuático para Gopro8, garantizará manipulación y protección al máximo bajo agua'
  }
];


export const BlackRampsProducts = [
  {
    cod: 100002,
    name: 'Caja de 2M',
    price: 480,
    img: './images/skateboarding/caja_vede.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100003,
    name: 'Cajón de 1.50Mx0.5M',
    price: 230,
    img: './images/skateboarding/cajon_150_x_50.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100004,
    name: 'Cajón 1.50Mx1M',
    price: 370,
    img: './images/skateboarding/cajon_150_x_100.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100005,
    name: 'Kicker 90cm',
    price: 270,
    img: './images/skateboarding/kicker_90.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
   {
    cod: 100001,
    name: 'Pirámide y tubo',
    price: 590,
    img: './images/skateboarding/botador_y_tubo.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100006,
    name: 'Quarter 90',
    price: 480,
    img: './images/skateboarding/quarter_90.jpg',
    desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100007,
    name: 'Miniramp 3.60 x 1.20 MTS',
    price: 4990,
    img: './images/skateboarding/miniramp.jpg',
    // desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100008,
    name: 'Mini Spine 60',
    price: 385,
    img: './images/skateboarding/spine.jpg',
    // desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  },
  {
    cod: 100009,
    name: 'Zapatera',
    price: 180,
    img: './images/skateboarding/zapatera_prototipo.jpg',
    // desc: 'Pechera diseñada para deportes extremos con suma estabilidad y seguridad de filmación.'
  }
];
