import { Modal } from 'antd';
import React from 'react';

const IntroModalComponent = ({ openModal, closeIntroModal = () => {} }) => {
  return (
    <Modal className="modal-intro"
    width={900} centered={true} visible={openModal} onCancel={() => closeIntroModal()} cancelText="CERRAR"
    okButtonProps={{hidden: true}} cancelButtonProps={{hidden: true}} closable={true}
>
  <button className="btn-modal-intro" onClick={() => closeIntroModal()}>INGRESAR</button>
  <img src="./images/modal.jpg" />
</Modal>
  )
}

export default IntroModalComponent;
